import React from "react";

type BackButtonProps = {
  text: string;
  onClick: () => void;
};
export const BackButton: React.FC<BackButtonProps> = (props) => {
  const { text, onClick } = props;

  return (
    <button
      type="button"
      onClick={onClick}
      className="flex justify-center flex-none items-center w-[160px] md:w-[180px] h-[50px]
              text-[12px] md:text-[14px] font-bold
              bg-disabled
              text-white text-sm tracking-[0.12em] antialiased
              rounded-xs mx-auto hover:opacity-75 transition-opacity duration-300"
    >
      {text}
    </button>
  );
};
