import * as React from "react";
import { BaseLayout } from "../components/views/layouts/BaseLayout";
import { StaticImage } from "gatsby-plugin-image";
import { useWindowSize } from "../hooks/useWindowSize";
import { UnderContentMv } from "../components/templates/UnderContentMv";
import { UnderContentLeadText } from "../components/parts/UnderContentLeadText";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { SubmitButton } from "../components/parts/SubmitButton";
import { FormError } from "../components/FormError";
import axios from "axios";
import * as Scroll from "react-scroll";
import { useEffect, useRef, useState } from "react";
import { Loading } from "../components/svg/Loading";
import { BackButton } from "../components/svg/BackButton";
import { BcItem } from "../components/views/Breadcrumb";
import { navigate } from "gatsby";
import { getParam } from "../utils/UrlUtils";

const WEBSITE_URL = "https://log-coffee-roasters.com/wp";
const FORM_ID = "7";
const bcList: BcItem[] = [{ href: "", text: "CONTACT" }];

const CHECKBOX_TEXTS = [
  "カフェ・コーヒー豆・器具について",
  "事業者様向コーヒー豆卸販売について",
  "コーヒー機器・器具の選定＆販売、サポートについて",
  "オンラインショップについて",
  "メンバー募集について",
  "その他",
];

const validationSchema = Yup.object({
  inquiryType: Yup.string().required("お問い合わせの種類を選択してください。"),
  yourName: Yup.string().required("お名前を入力してください。"),
  yourEmail: Yup.string()
    .email("メールアドレスの形式が不正です。")
    .oneOf(
      [Yup.ref("yourEmailConfirm")],
      "メールアドレス（確認）と一致していません。"
    )
    .required("メールアドレスを入力してください。"),
  yourMessage: Yup.string().required("お問い合わせ内容を入力してください。"),
});

const initValues = {
  inquiryType: "",
  yourName: "",
  yourCompany: "",
  yourEmail: "",
  yourEmailConfirm: "",
  yourMessage: "",
};

const ContactPage = () => {
  const [windowWidth, windowHeight] = useWindowSize();
  const [isConfirm, setIsConfirm] = useState(false);
  const contactForm = useRef<HTMLFormElement>(null);
  const scroll = Scroll.animateScroll;

  const handleSubmit = async (values: typeof initValues) => {
    if (isConfirm) {
      // 確認 --> 送信
      const formData = new FormData();
      formData.set("inquiryType", values.inquiryType);
      formData.set("yourName", values.yourName);
      formData.set("yourCompany", values.yourCompany);
      formData.set("yourEmail", values.yourEmail);
      formData.set("yourMessage", values.yourMessage);

      const response = await axios.post(
        `${WEBSITE_URL}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
        formData
      );

      // console.log(response.data);

      if (response.data.status === "mail_sent") {
        // 送信成功
        navigate("/thanks");
      }
    } else {
      // 入力 --> 確認
      setIsConfirm(true);
      const formElem = contactForm.current;
      const formElemPos =
        window.scrollY + formElem!.getBoundingClientRect().top;
      scroll.scrollTo(formElemPos - 100, {
        duration: 800,
        delay: 0,
        smooth: "easeOutQuart",
      });
    }
  };

  useEffect(() => {
    const iqrQuery = getParam("iqr");
    if (iqrQuery && !isNaN(Number(iqrQuery))) {
      initValues.inquiryType = CHECKBOX_TEXTS[Number(iqrQuery)];
    }
  }, []);

  return (
    <BaseLayout
      bcList={bcList}
      showOnlineShopButton={false}
      pageUrl="/contact"
      pageTitle="お問い合せ"
      pageDescription="お店に関すること、オンラインショップに関すること、コーヒー豆・コーヒーのご利用をお考えの事業者さま…どんなことでも、お気軽にご連絡ください。ご連絡お待ちしております。"
    >
      <UnderContentMv jaTitle="お問い合せ" enTitle="CONTACT">
        {windowWidth < 480 ? (
          <StaticImage
            src="../images/mv/contact.jpg"
            alt="CONTACT メイン画像"
          />
        ) : (
          <div className="h-[420px] lg:h-[580px]">
            <StaticImage
              src="../images/mv/contact_pc.jpg"
              alt="CONTACT メイン画像"
              className="absolute inset-y-0 m-auto"
            />
          </div>
        )}
      </UnderContentMv>
      <UnderContentLeadText>
        お店に関すること、オンラインショップに関すること、
        <br className="md:hidden" />
        コーヒー豆・コーヒーのご利用をお考えの事業者さま…
        <br />
        どんなことでも、お気軽にご連絡ください。
        <br />
        ご連絡お待ちしております。
      </UnderContentLeadText>
      <Formik
        initialValues={initValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, isValid, isSubmitting }) => (
          <Form className="max-w-[680px] mx-auto px-5" ref={contactForm}>
            <div className="mb-[40px]">
              <fieldset>
                <legend className="text-base md:text-md leading-none block mb-3">
                  お問合せ内容
                </legend>
                <div className="flex flex-wrap">
                  {isConfirm ? (
                    <p
                      className="block w-[100%]
                      text-base md:text-md
                      px-2 py-3
                      leading-none outline-none
                      antialiased
                      focus:border-primary focus:border-2 appearance-none"
                    >
                      {values.inquiryType}
                    </p>
                  ) : (
                    CHECKBOX_TEXTS.map((text, index) => (
                      <div key={text} className="mr-8 mb-3">
                        <Field
                          type="radio"
                          id={`inquiry-${index}`}
                          name="inquiryType"
                          value={text}
                          className="hidden peer"
                        />
                        <label
                          htmlFor={`inquiry-${index}`}
                          className="relative before:content before:inline-block before:w-[14px]
                        before:h-[14px] before:border before:border-1
                        before:rounded-xs before:relative before:top-[1px] before:mr-2
                        peer-checked:before:bg-primary peer-checked:before:border-primary
                        pseudo-after after:block after:bg-check-white after:w-[9px] after:h-[7px]
                        after:absolute after:left-[3px] after:top-[3px] md:after:top-1"
                        >
                          {text}
                        </label>
                      </div>
                    ))
                  )}
                </div>
              </fieldset>
              {errors.inquiryType && (
                <FormError errorMessage={errors.inquiryType} />
              )}
            </div>
            <div className="mb-[40px]">
              <label
                htmlFor="yourName"
                className="text-base md:text-md leading-none block mb-3"
              >
                お名前
              </label>
              <Field
                type="text"
                name="yourName"
                className="block w-[100%] border-black border
                rounded-[6px] text-base md:text-md
                px-2 py-3
                leading-none outline-none
                placeholder:text-placeholder
                antialiased
                focus:border-primary focus:border-2 appearance-none
                read-only:border-none"
                placeholder="武本 直樹"
                readOnly={isConfirm}
              />
              {errors.yourName && <FormError errorMessage={errors.yourName} />}
            </div>
            <div className="mb-[40px]">
              <label
                htmlFor="yourCompany"
                className="text-base md:text-md leading-none block mb-3"
              >
                事業者名
                <span className="text-sm md:text-base text-green">
                  （事業者さまはご記入をお願いいたします）
                </span>
              </label>
              <Field
                type="text"
                name="yourCompany"
                className="block w-[100%] border-black border
                rounded-[6px] text-base md:text-md
                px-2 py-3
                leading-none outline-none
                placeholder:text-placeholder
                antialiased
                focus:border-primary focus:border-2 appearance-none
                read-only:border-none"
                placeholder="LOG COFFEE ROASTERS"
                readOnly={isConfirm}
              />
              {errors.yourCompany && (
                <FormError errorMessage={errors.yourCompany} />
              )}
            </div>
            <div className="mb-[40px]">
              <label
                htmlFor="yourEmail"
                className="text-base md:text-md leading-none block mb-3"
              >
                メールアドレス
              </label>
              <Field
                type="email"
                name="yourEmail"
                className="block w-[100%] border-black border
                rounded-[6px] text-base md:text-md
                px-2 py-3
                leading-none outline-none
                placeholder:text-placeholder
                antialiased
                focus:border-primary focus:border-2 appearance-none
                read-only:border-none"
                placeholder="log.coffee.roasters@gmail.com"
                readOnly={isConfirm}
              />
              {errors.yourEmail && (
                <FormError errorMessage={errors.yourEmail} />
              )}
            </div>
            {!isConfirm && (
              <div className="mb-[40px]">
                <label
                  htmlFor="yourEmailConfirm"
                  className="text-base md:text-md leading-none block mb-3"
                >
                  メールアドレス（確認）
                </label>
                <Field
                  type="email"
                  name="yourEmailConfirm"
                  className="block w-[100%] border-black border
                rounded-[6px] text-base md:text-md
                px-2 py-3
                leading-none outline-none
                placeholder:text-placeholder
                antialiased
                focus:border-primary focus:border-2 appearance-none"
                  placeholder="log.coffee.roasters@gmail.com"
                />
              </div>
            )}
            <div className="mb-10 md:mb-[75px]">
              <label
                htmlFor="yourMessage"
                className="text-base md:text-md leading-none block mb-3"
              >
                お問合せ内容
              </label>
              <Field
                as="textarea"
                name="yourMessage"
                className={`block w-[100%] ${
                  isConfirm ? "" : "h-[180px]"
                } border-black border
                rounded-[6px] text-base md:text-md
                px-2 py-3
                leading-[1.5] outline-none
                placeholder:text-placeholder
                antialiased
                focus:border-primary focus:border-2 appearance-none
                read-only:border-none resize-none`}
                placeholder="お問合せ内容をご記入ください。"
                readOnly={isConfirm}
              />
              {errors.yourMessage && (
                <FormError errorMessage={errors.yourMessage} />
              )}
            </div>
            {isSubmitting ? (
              <Loading />
            ) : (
              <div>
                <SubmitButton
                  isConfirm={isConfirm}
                  disabled={isSubmitting || !isValid}
                />
                {isConfirm && (
                  <div className="mt-5">
                    <BackButton
                      text="修正する"
                      onClick={() => setIsConfirm(false)}
                    />
                  </div>
                )}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </BaseLayout>
  );
};

export default ContactPage;
