import React from "react";

type SubmitButtonProps = {
  disabled: boolean;
  isConfirm: boolean;
};

export const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const { disabled, isConfirm } = props;

  return (
    <button
      type="submit"
      className={`flex justify-center flex-none items-center w-[160px] md:w-[180px] h-[50px]
                text-[12px] md:text-[14px] font-bold bg-primary
                text-white text-sm tracking-[0.12em] antialiased
                rounded-xs mx-auto hover:opacity-75 transition-opacity duration-300
                disabled:bg-disabled disabled:pointer-events-none`}
      disabled={disabled}
    >
      {isConfirm ? "送信" : "入力内容の確認"}
    </button>
  );
};
