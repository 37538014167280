/**
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 * @param  url {url} 対象のURL文字列（任意）
 */
export const getParam = (name: string, url?: string) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
